import React, { useRef, useEffect, useState } from "react"
import { Box, Text } from "rebass/styled-components"
import { connect } from "react-redux"
import { AdSlot, DFPManager } from "react-dfp"
import { useIsVisible } from "react-is-visible"
import RatioBox from "../../components/UI/RatioBox"
const Pub = ({ isMap, slotId, readyForAds }) => {
  const [isEmpty, setIsEmpty] = useState(false)
  const nodeRef = useRef()
  const isVisible = useIsVisible(nodeRef)
  useEffect(() => {
    let timer1
    // console.log(slotId + " isVisible", isVisible);
    if (isVisible && isEmpty) {
      // console.log(slotId+ " est vide, on le recharge");
      DFPManager.reload([slotId])
      setIsEmpty(false)
    }
    if (isVisible && !isEmpty) {
      timer1 = setTimeout(function () {
        setIsEmpty(true)
      }, 30000)
    }
    return () => {
      clearTimeout(timer1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible,isEmpty])
  return (
    <Box ref={nodeRef} pr={isMap ? [0] : 0}>
      <Box
        mx="auto"
        pb={2}
        sx={{
          iframe: {
            margin: "0 auto",
            display: "block",
          },
          position: "relative",
          overflowX: "hidden",
        }}
      >
        <Text pt={1} pl={isMap ? 2 : 0} fontSize="12rem" color="gray">
          Annonce commerciale
        </Text>
        <Box
          minHeight={["87px", "120px"]}
          sx={{ transform: ["scale(.7)", "none"] }}
        >
          <RatioBox ratio={120 / 440} maxWidth="440px" margin="0 auto">
            {isVisible && readyForAds && (
              <AdSlot
                slotId={slotId}
                sizes={[[440, 120]]}
                adUnit="banner440"
                onSlotRender={renderEvent => {
                  setIsEmpty(renderEvent.event.isEmpty)
                }}
              />
            )}
          </RatioBox>
        </Box>
      </Box>
      <Box height="1px" bg="#d8d8d8" />
    </Box>
  )
}

const mapStateToProps = state => ({
  isMap: state.global.isMap,
})
export default connect(mapStateToProps)(Pub)
