import React, { useRef, useState, useEffect } from "react"
import ReactDOM from "react-dom"
import { Button } from "rebass/styled-components"
import "maplibre-gl/dist/maplibre-gl.css"
import { prepareGeoJson } from "./geoJson"
import { alllayers } from "./layers"
import { connect } from "react-redux"
import { setFocusedNode } from "../../../redux/actions/currentNodeActions"
import {
  setUserPosition,
  setFirstLocation,
} from "../../../redux/actions/globalAction"
import useGeolocation from "../../../utils/geo"

import { getValue, getDistance } from "../../../utils"
import { navigate } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  #map-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    .map {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
  .maplibregl-popup-content {
    padding: 10px;
    text-align: center;
  }
  .debug-info {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  .maplibregl-ctrl-bottom-left,
  .maplibregl-ctrl-bottom-right,
  .maplibregl-ctrl-top-left,
  .maplibregl-ctrl-top-right {
    z-index: 1;
  }
  .user-marker {
    background-size: cover;
    width: 54px;
    height: 62px;
    cursor: pointer;
    margin-top: -28px;
    .inner {
      width: 54px;
      height: 62px;
      position: relative;
      .pulse {
        background: transparent;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: 23px 0px 0px -7px;
        -webkit-transform: scale(2) rotateX(80deg);
        -moz-transform: scale(2) rotateX(80deg);
        -o-transform: scale(2) rotateX(80deg);
        -ms-transform: scale(2) rotateX(80deg);
        transform: scale(2) rotateX(80deg);
        z-index: 2;
        &:after {
          content: "";
          border-radius: 50%;
          height: 40px;
          width: 40px;
          position: absolute;
          margin: -13px 0 0 -13px;
          -webkit-animation: pulsate 1s ease-out;
          -moz-animation: pulsate 1s ease-out;
          -o-animation: pulsate 1s ease-out;
          -ms-animation: pulsate 1s ease-out;
          animation: pulsate 1s ease-out;
          -webkit-animation-iteration-count: infinite;
          -moz-animation-iteration-count: infinite;
          -o-animation-iteration-count: infinite;
          -ms-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
          -webkit-box-shadow: 0 0 1px 2px #7caf41;
          box-shadow: 0 0 1px 2px #7caf41;
        }
      }
      @-moz-keyframes pulsate {
        0% {
          -webkit-transform: scale(0.1, 0.1);
          -moz-transform: scale(0.1, 0.1);
          -o-transform: scale(0.1, 0.1);
          -ms-transform: scale(0.1, 0.1);
          transform: scale(0.1, 0.1);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
        50% {
          opacity: 1;
          -ms-filter: none;
          filter: none;
        }
        100% {
          -webkit-transform: scale(1.2, 1.2);
          -moz-transform: scale(1.2, 1.2);
          -o-transform: scale(1.2, 1.2);
          -ms-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
      }
      @-webkit-keyframes pulsate {
        0% {
          -webkit-transform: scale(0.1, 0.1);
          -moz-transform: scale(0.1, 0.1);
          -o-transform: scale(0.1, 0.1);
          -ms-transform: scale(0.1, 0.1);
          transform: scale(0.1, 0.1);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
        50% {
          opacity: 1;
          -ms-filter: none;
          filter: none;
        }
        100% {
          -webkit-transform: scale(1.2, 1.2);
          -moz-transform: scale(1.2, 1.2);
          -o-transform: scale(1.2, 1.2);
          -ms-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
      }
      @-o-keyframes pulsate {
        0% {
          -webkit-transform: scale(0.1, 0.1);
          -moz-transform: scale(0.1, 0.1);
          -o-transform: scale(0.1, 0.1);
          -ms-transform: scale(0.1, 0.1);
          transform: scale(0.1, 0.1);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
        50% {
          opacity: 1;
          -ms-filter: none;
          filter: none;
        }
        100% {
          -webkit-transform: scale(1.2, 1.2);
          -moz-transform: scale(1.2, 1.2);
          -o-transform: scale(1.2, 1.2);
          -ms-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
      }
      @keyframes pulsate {
        0% {
          -webkit-transform: scale(0.1, 0.1);
          -moz-transform: scale(0.1, 0.1);
          -o-transform: scale(0.1, 0.1);
          -ms-transform: scale(0.1, 0.1);
          transform: scale(0.1, 0.1);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
        50% {
          opacity: 1;
          -ms-filter: none;
          filter: none;
        }
        100% {
          -webkit-transform: scale(1.2, 1.2);
          -moz-transform: scale(1.2, 1.2);
          -o-transform: scale(1.2, 1.2);
          -ms-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
      }
    }
    img {
      width: 54px;
      height: 62px;
      position: relative;
      z-index: 3;
    }
  }
`

const Map = ({
  mapOptions,
  zoom,
  lon,
  lat,
  markers,
  className,
  dispatch,
  currentNode,
  userPosition,
  searchAround,
  isMobile,
  hasFirstLocation,
}) => {
  const geolocation = useGeolocation({
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 27000,
  })

  useEffect(() => {
    // console.log(markers, "markers")
    if (
      (geolocation.longitude !== userPosition[0] ||
        geolocation.latitude !== userPosition[1]) &&
      !geolocation.error &&
      !geolocation.loading &&
      userPosition === false
    ) {
      if (userPosition === false) {
        dispatch(setUserPosition([geolocation.longitude, geolocation.latitude]))
      } else {
        if (
          getDistance(
            { lat: userPosition[1], lon: userPosition[0] },
            {
              lat: parseFloat(geolocation.latitude),
              lon: parseFloat(geolocation.longitude),
            }
          ) > 20
        ) {
          dispatch(
            setUserPosition([geolocation.longitude, geolocation.latitude])
          )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geolocation])

  lat = lat || -21.36994791550339
  lon = lon || 165.6702950247892
  zoom = zoom || 7
  mapOptions = mapOptions || {}
  const offsetPopup = {
    company: 45,
    les_entreprises: 45,
    street: 0,
    les_lieux: 35,
  }
  const { allNodeCompany } = useStaticQuery(graphql`
    query {
      allNodeCompany(
        filter: {
          field_poi_enable: { eq: true }
          field_geofield: { lat: { ne: null }, lon: { ne: null } }
        }
      ) {
        nodes {
          field_poi_enable
          drupal_internal__nid
          field_geofield {
            lat
            lon
          }
          title
        }
      }
    }
  `)
  const markersNid = markers.map((marker) => marker.drupal_internal__nid)
  const reperes = allNodeCompany.nodes
    .filter((node) => markersNid.indexOf(node.drupal_internal__nid) < 0)
    .map((node) => ({
      center: [
        getValue(node, "field_geofield", "lon"),
        getValue(node, "field_geofield", "lat"),
      ],
      title: node.title,
    }))
  const containerRef = useRef()
  const [map, setMap] = useState(false)
  const [currentPopup, setCurrentPopup] = useState(false)
  const [userMarker, setUserMarker] = useState(false)
  const [mapIsCentered, setMapIsCentered] = useState(false)
  const [mapCenter, setMapCenter] = useState(false)
  const [onUserAndPoi, setOnUserAndPoi] = useState(false)
  const afterMove = (e) => {
    setMapCenter(e.target.getCenter().toArray())
  }

  useEffect(() => {
    if (mapCenter && userPosition) {
      setMapIsCentered(
        parseFloat(mapCenter[0]).toFixed(4) ===
          parseFloat(userPosition[0]).toFixed(4) &&
          parseFloat(mapCenter[1]).toFixed(4) ===
            parseFloat(userPosition[1]).toFixed(4)
      )
    }
  }, [mapCenter, userPosition])

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }
    const maplibregl = require("maplibre-gl")

    const clickOnUnCluster = (e) => {
      if (!map) {
        return
      }
      var features = map.queryRenderedFeatures(e.point, {
        layers: ["unclustered-point"],
      })
      if (features[0].properties?.path) {
        // console.log(
        //   {
        //     feature1: features[0].properties,
        //     currentNode: currentNode.focusedItem,
        //   },
        //   "feature1"
        // )
        if (
          !currentNode.focusedItem ||
          (currentNode.focusedItem &&
            currentNode.focusedItem.drupal_internal__nid !==
              features[0].properties.drupal_internal__nid)
        ) {
          dispatch(
            setFocusedNode(JSON.parse(features[0].properties.node), true)
          )
        } else {
          navigate(features[0].properties.path)
          dispatch(setFocusedNode(false))
        }
      }
    }
    const clickOnCluster = (e) => {
      if (!map) {
        return
      }
      map.getCanvas().style.cursor = "pointer"
      var features = map.queryRenderedFeatures(e.point, {
        layers: ["clusters"],
      })
      // console.log(features, "clickOnCluster")
      var clusterId = features[0].properties.cluster_id
      map
        .getSource("pois")
        .getClusterExpansionZoom(clusterId)
        .then((clusterZoom) => {
          map.easeTo({
            center: features[0].geometry.coordinates,
            padding: 40,
            zoom: clusterZoom + 1,
          })
        })

      //       map
      //         .getSource("pois")
      //         .getClusterExpansionZoom(clusterId, function (err, zoom) {
      //           if (err) {
      //             console.log(err, "clickOnCluster err")
      //             return
      //           }
      // console.log(features[0], "clickOnCluster features[0]")
      //           map.easeTo({
      //             center: features[0].geometry.coordinates,
      //             padding: 40,
      //             zoom: zoom + 1,
      //           })
      //         })
    }
    const userPosLayer = {
      id: "user",
      type: "symbol",
      source: "user-position",
      layout: {
        "icon-allow-overlap": true,
        "icon-image": ["image", "user"],
        "icon-size": 0.9,
        "icon-anchor": "bottom",
      },
    }
    const reperesLayer = {
      id: "reperes",
      type: "symbol",
      source: "reperes",
      minzoom: 17,
      layout: {
        // Set the label content to the
        // feature's `name` property
        "text-field": ["get", "title"],
        "text-font": ["Open Sans Regular"],
        "text-size": 11,
      },
      paint: {
        "text-color": "#000",
        "text-halo-blur": 1,
        "text-halo-color": "#fff",
        "text-halo-width": 1,
      },
    }
    const mapSettings = {
      container: containerRef.current,
      center: [lon, lat],
      offset: [0, 0], //h,v
      style: "https://map.serveur.nc/styles/plannc/style.json",
      zoom: zoom,
      minZoom: 7,
      maxZoom: 18,
      scrollZoom: true,
      interactive: true,
      ...mapOptions,
    }
    const map = new maplibregl.Map(mapSettings)

    map.dragRotate.disable()
    // disable map rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation()
    map.on("load", async () => {
      const markersIcons = [
        "gratuit",
        "group",
        "gold",
        "premium",
        "lieux",
        "place",
        "street",
        "icon-shopping-bag",
        "icon-poi_ilots",
        "icon-automobile",
        "icon-poi_parks",
        "user",
      ]
      for (var i = 0; i < markersIcons.length; i++) {
        var image = null
        const imageId = markersIcons[i]
        image = await map.loadImage("/markers/" + imageId + ".png?clear")
        if (!map.hasImage(imageId)) map.addImage(imageId, image.data)
      }
    })
    map.on("load", () => {
      // map.on("dragend", afterMove)
      // map.on("wheel", afterMove)
      map.on("moveend", afterMove)
      var el = document.createElement("div")
      el.className = "user-marker"
      ReactDOM.render(
        <div className="inner">
          <img src="/markers/user.svg" alt="Vous êtes ici" />
          <div className="pulse"></div>
        </div>,
        el
      )
      const userMarkerObj = new maplibregl.Marker({ element: el })
      setUserMarker(userMarkerObj)
      map.addControl(new maplibregl.ScaleControl(), "bottom-right")
      const geojson = prepareGeoJson(markers)

      map.addSource("reperes", {
        type: "geojson",
        data: prepareGeoJson(reperes, true),
      })
      map.addLayer(reperesLayer)

      map.addSource("pois", {
        type: "geojson",
        data: geojson,
        cluster: true,
        clusterMaxZoom: 13,
        clusterRadius: 100,
      })
      map.addSource("current-poi", {
        type: "geojson",
        data: geojson,
        cluster: false,
      })
      map.addSource("user-position", {
        type: "geojson",
        data: prepareGeoJson([], true),
        cluster: false,
      })
      map.addSource("geojsons", {
        type: "geojson",
        data: prepareGeoJson(markers, true),
      })

      for (var i2 = 0; i2 < alllayers.length; i2++) {
        map.addLayer(alllayers[i2])
      }
      map.addLayer(userPosLayer)

      map.on("click", "clusters", (e) => {
        clickOnCluster(e)
      })
      map.on("click", "unclustered-point", (e) => {
        console.log("clickOnUnCluster")
        clickOnUnCluster(e)
      })

      // Create a popup, but don't add it to the map yet.
      var popup = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false,
        anchor: "bottom",
      })
      const handleMouseEnter = (e) => {
        map.getCanvas().style.cursor = "pointer"
        if (e.features[0].properties.isCurrent) {
          return
        }
        var coordinates = e.features[0].geometry.coordinates.slice()
        var description = e.features[0].properties.title
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        popup.options.offset = offsetPopup[e.features[0].properties.type]
        // e.features[0].properties.type === "les_voies" ? 0 : 45
        popup.setLngLat(coordinates).setHTML(description).addTo(map)
      }
      map.on("mouseenter", "unclustered-point", handleMouseEnter)

      map.on("mouseleave", "unclustered-point", function () {
        map.getCanvas().style.cursor = ""
        popup.remove()
      })
      map.on("mouseenter", "clusters", function () {
        map.getCanvas().style.cursor = "pointer"
      })
      map.on("mouseleave", "clusters", function () {
        map.getCanvas().style.cursor = ""
      })
      setMap(map)
      map.resize()
    })
    return function cleanup() {
      if (map) {
        map.remove()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (markers && map) {
      if (map.getSource("geojsons")) {
        map.getSource("geojsons").setData(prepareGeoJson(markers, true))
        // console.log(
        //   prepareGeoJson(markers, true),
        //   "prepareGeoJson(markers, true)"
        // )
      }
      const geojson = prepareGeoJson(markers)
      if (map.getSource("pois")) {
        map.getSource("pois").setData(geojson)
        if (
          markers.length > 0 &&
          markers.filter((marker) => marker.isCurrent).length === 0
        ) {
          const maplibregl = require("maplibre-gl")
          var coordinates = markers.map((marker) => {
            return marker.center
          })
          var bounds = coordinates.reduce(
            function (bounds, coord) {
              return bounds.extend(coord)
            },
            new maplibregl.LngLatBounds(coordinates[0], coordinates[0])
          )

          map.fitBounds(bounds, {
            padding: 40,
            maxDuration: 300,
          })
        }
      }
      if (currentPopup) {
        currentPopup.remove()
      }
      if (map.getSource("current-poi")) {
        map.getSource("current-poi").setData(geojson)
        if (markers.filter((marker) => marker.isCurrent).length > 0) {
          const marker = markers.filter((marker) => marker.isCurrent)[0]
          const flytoOptions = { maxDuration: 0, center: marker.center }
          flytoOptions.maxDuration = 300
          // if (!currentNode.fromMap) {
          //   flytoOptions.zoom = 17
          // }
          flytoOptions.zoom = 17
          map.flyTo(flytoOptions)
          const maplibregl = require("maplibre-gl")

          var popup = new maplibregl.Popup({
            closeButton: false,
            closeOnClick: false,
            anchor: "bottom",
            offset: offsetPopup[marker.type],
          })
          popup.setLngLat(marker.center).setHTML(marker.title).addTo(map)
          setCurrentPopup(popup)
        } else {
          if (userPosition !== false && searchAround) {
            userMarker.setLngLat(userPosition).addTo(map)

            const maplibregl = require("maplibre-gl")
            var coordinatesOfMarkers = markers.map((marker) => {
              return marker.center
            })
            coordinatesOfMarkers.push(userPosition)
            var boundsOfMarkers = coordinatesOfMarkers.reduce(
              function (boundsOfMarkers, coord) {
                return boundsOfMarkers.extend(coord)
              },
              new maplibregl.LngLatBounds(
                coordinatesOfMarkers[0],
                coordinatesOfMarkers[0]
              )
            )

            map.fitBounds(boundsOfMarkers, {
              padding: 40,
              maxDuration: 300,
            })
          }
        }
      }
    }
    if (map) {
      if (userPosition !== false) {
        userMarker.setLngLat(userPosition).addTo(map)
        // const maplibregl = require("maplibre-gl")
        // new maplibregl.Marker({ color: "blue" })
        //   .setLngLat(userPosition)
        //   .addTo(map)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers, map])

  useEffect(() => {
    if (map) {
      map.resize()
    }
  }, [className, map])

  useEffect(() => {
    if (currentNode.fromMap) {
      var resultElement = document.getElementById(
        "result-nid-" + currentNode.focusedItem.drupal_internal__nid
      )
      if (resultElement) {
        resultElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
        setTimeout(function () {
          resultElement = document.getElementById(
            "result-nid-" + currentNode.focusedItem.drupal_internal__nid
          )
          if (resultElement) {
            resultElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        }, 800)
      }
    }
  }, [currentNode.fromMap, currentNode.focusedItem])

  // useEffect(() => {
  //   if (userPosition && map && mapIsCentered && !onUserAndPoi) {
  //     map.flyTo({ maxDuration: 0, center: userPosition })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mapIsCentered])

  useEffect(() => {
    if (userPosition && map && userMarker) {
      userMarker.setLngLat(userPosition).addTo(map)
      if (!hasFirstLocation) {
        map.flyTo({ maxDuration: 0, center: userPosition, zoom: 14 })
        dispatch(setFirstLocation(true))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPosition, map, userMarker])

  // useEffect(() => {
  //   if (currentNode.focusedItem) {
  //     setMapIsCentered(false)
  //   }
  // }, [currentNode.focusedItem])

  useEffect(() => {
    setOnUserAndPoi(false)
  }, [currentNode.item, currentNode.focusedItem])

  useEffect(() => {
    if (
      userPosition &&
      map &&
      (currentNode.item || currentNode.focusedItem) &&
      !onUserAndPoi
    ) {
      if (map.getSource("current-poi") && userPosition) {
        if (markers.filter((marker) => marker.isCurrent).length > 0) {
          const marker = markers.filter((marker) => marker.isCurrent)[0]
          const coordsPoints = [marker.center, userPosition]
          const maplibregl = require("maplibre-gl")
          const bounds = coordsPoints.reduce(
            (bounds, coord) => {
              return bounds.extend(coord)
            },
            new maplibregl.LngLatBounds(coordsPoints[0], coordsPoints[0])
          )
          map.fitBounds(bounds, {
            padding: 100,
          })
          setOnUserAndPoi(true)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNode.item, currentNode.focusedItem, markers, userPosition, map])

  return (
    <MapWrapper className={className || ""}>
      <div id="map-wrapper">
        <div ref={containerRef} className="map" />
        {isMobile && userPosition && (
          <Button
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              borderRadius: 999,
              backgroundColor: "white !important",
              lineHeight: 0,
              circle: {
                fill:
                  mapIsCentered && userPosition !== false
                    ? "danger"
                    : "currentColor",
              },
            }}
            onClick={() => {
              if (userPosition && map && !mapIsCentered) {
                map.flyTo({
                  maxDuration: 0,
                  center: userPosition,
                })
              }
            }}
            color="text"
            m={2}
            p={1}
          >
            <svg
              viewBox="0 0 500 500"
              width="30"
              height="30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 285.151 0 L 285.151 66 C 360.35 80.324 419.7 139.649 434.025 214.851 L 434.025 214.851 L 500 214.851 L 500 285.175 L 434.025 285.175 C 419.7 360.374 360.35 419.699 285.151 434.025 L 285.151 434.025 L 285.151 500 L 214.851 500 L 214.851 434.025 C 139.651 419.7 80.3 360.376 65.975 285.175 L 65.975 285.175 L 0 285.175 L 0 214.851 L 65.975 214.851 C 80.3 139.651 139.651 80.327 214.851 66 L 214.851 66 L 214.851 0 L 285.151 0 Z M 250.001 119.861 C 178.252 119.861 119.834 178.249 119.834 249.999 C 119.834 321.777 178.252 380.166 250.001 380.166 C 321.751 380.166 380.167 321.777 380.167 249.999 C 380.167 178.249 321.751 119.861 250.001 119.861 Z M 250.001 172.974 C 292.556 172.974 327.029 207.474 327.029 250.001 C 327.029 292.556 292.556 327.057 250.001 327.057 C 207.445 327.057 172.974 292.556 172.974 250.001 C 172.974 207.474 207.445 172.974 250.001 172.974 Z"
                fill="currentColor"
              />
              <circle
                // fill={mapIsCentered ? "rgb(247, 0, 0)" : "currentColor"}
                cx="249.999"
                cy="250.001"
                r="79.59"
              />
            </svg>
          </Button>
        )}
      </div>
    </MapWrapper>
  )
}
const mapStateToProps = (state) => ({
  currentNode: state.currentNode,
  userPosition: state.global.userPosition,
  searchAround: state.global.searchAround,
  isMobile: state.global.isMobile,
  hasFirstLocation: state.global.hasFirstLocation,
})

export default connect(mapStateToProps)(Map)
