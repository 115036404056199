import React, { useEffect } from "react"
import { solrReset } from "../redux/actions/SolrActions"
import { Box } from "rebass/styled-components"
import { setIsMap } from "../redux/actions/globalAction"
import { connect } from "react-redux"
import Page from "../templates/Page"
import Categories from "../components/UI/Categories"
import { AdSlot, DFPSlotsProvider } from "react-dfp"
import Seo from "../components/layout/seo"

export const Head = (props) => {
  return <Seo {...props} />
}

const IndexPage = ({
  isMap,
  forceMap,
  dispatch,
  location,
  newSession,
}) => {
  forceMap = forceMap === undefined ? true : forceMap
  useEffect(() => {
    dispatch(solrReset())
    if (location.pathname === "/" && !isMap && newSession) {
      dispatch(setIsMap(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Page title="" location={location} forceMap={forceMap}>
      <Categories />
      <Box mt={2} sx={{ overflow: "hidden" }}>
        <Box
          height={["232.5px", "465px"]}
          width={["232.5px", "465px"]}
          mx="auto"
          sx={{
            iframe: {
              transform: ["scale(.5)", "scale(1)"],
              ml: ["-116.25px", 0],
              mt: ["-116.25px", 0],
            },
          }}
        >
          <DFPSlotsProvider dfpNetworkId="21617937854">
            <AdSlot
              sizes={[[465, 465]]}
              slotId="home-page"
              adUnit="home_page"
            />
          </DFPSlotsProvider>
        </Box>
      </Box>
    </Page>
  )
}

const mapStateToProps = state => ({
  isMap: state.global.isMap,
  newSession: state.global.newSession,
})


export default connect(mapStateToProps)(IndexPage)
