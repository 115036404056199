import { store } from "../store"
import {  getDistance, cleanString } from "../../utils"
import orderBy from "lodash/orderBy"

export const FETCH_SOLR_LOAD = "FETCH_SOLR_LOAD"
export const FETCH_SOLR_LOAD_SUCCESS = "FETCH_SOLR_LOAD_SUCCESS"
export const FETCH_SOLR_LOAD_ERROR = "FETCH_SOLR_LOAD_ERROR"
export const SOLR_SET_WORD = "SOLR_SET_WORD"
export const SOLR_RESET = "SOLR_RESET"
export const SOLR_RESET_FILTERS = "SOLR_RESET_FILTERS"
export const SOLR_RESET_ITEMS = "SOLR_RESET_ITEMS"
export const SOLR_SET_ITEMS = "SOLR_SET_ITEMS"

export const SOLR_SET_LAST_LIST_PATH = "SOLR_SET_LAST_LIST_PATH"

const mainTransformResponse = (data) => {
  const searchAround = store.getState().global.searchAround
  const userPosition = store.getState().global.userPosition
  data = data || []
  if (typeof data === "string") {
    data = JSON.parse(data)
  }
  // console.log(process?.env?.NODE_ENV, "process?.env?.NODE_ENV")
  const im_field_categories = {}
  const is_field_nom_ville = {}

  const items = data.map((dataItem) => {
    dataItem.field_co_cats_name = cleanString(dataItem.field_co_cats_name)
    dataItem.title = cleanString(dataItem.title)
    dataItem.field_city = cleanString(dataItem.field_city)
    dataItem.field_street_text = cleanString(dataItem.field_street_text)
    dataItem.field_city_name = cleanString(dataItem.field_city_name)
    dataItem.field_district_title = cleanString(dataItem.field_district_title)
    dataItem.field_street_title = cleanString(dataItem.field_street_title)
    dataItem.field_street_prefix = cleanString(dataItem.field_street_prefix)
    const position = dataItem.latlon.split(",")
    position[0] = position[0] || 0
    position[1] = position[1] || 0
    if (is_field_nom_ville[dataItem.field_city_tid] === undefined) {
      is_field_nom_ville[dataItem.field_city_tid] = {
        count: 1,
        name: dataItem.field_city_name,
        nid: dataItem.field_city_tid,
      }
    } else {
      is_field_nom_ville[dataItem.field_city_tid].count++
    }
    var allcategories = dataItem.tid.split(",")
    var categorie = allcategories[allcategories.length - 1]

    var categoriename = dataItem.field_co_cats.split(",")

    if (im_field_categories[categorie] === undefined) {
      im_field_categories[categorie] = {
        count: 1,
        name: categoriename[categoriename.length - 1],
        tid: categorie,
      }
    } else {
      im_field_categories[categorie].count++
    }
    allcategories.map((cat, index) => {
      if (cat !== categorie) {
        if (im_field_categories[cat] === undefined) {
          im_field_categories[cat] = {
            count: 1,
            name: categoriename[index],
            tid: cat,
          }
        } else {
          im_field_categories[cat].count++
        }
      }
      return null
    })
    var distance = 0
    if (searchAround !== false && userPosition !== false) {
      distance = getDistance(
        { lat: userPosition[1], lon: userPosition[0] },
        {
          lat: parseFloat(position[0]),
          lon: parseFloat(position[1]),
        }
      )
    }

    return {
      ...dataItem,
      distance: distance,
      // drupal_id: "de73b700-84ad-48c2-b2b5-c6ca2016fc7f",
      field_rdv_enable: dataItem?.field_rdv_enable === "1" ? true : false,
      drupal_internal__nid: dataItem.nid,
      field_city_tid: dataItem.field_city_tid,
      nid: dataItem.nid,
      field_address_nb: dataItem.field_address_nb,
      field_address_nb_text: dataItem.field_address_nb_text,
      field_mailbox_text: "98890 Païta",
      field_geofield: {
        lat: parseFloat(position[0]),
        lon: parseFloat(position[1]),
        geom: dataItem.geom,
      },
      field_street: {
        drupal_internal__target_id: 570,
      },
      field_street_text: dataItem.field_street_text,
      field_zip_code: dataItem.field_zip_code,
      path: {
        alias: dataItem.url, //"/mode-pret-a-porter/boutique-sport/healthyshop-nc",
        langcode: "fr",
        pid: 94800,
      },
      title: dataItem.title,
      type: dataItem.type,
      node_type: {
        drupal_internal__target_id: dataItem.type,
      },
      relationships: {
        field_city: {
          name: dataItem.field_city_name,
        },
        field_co_cats: [
          {
            name: dataItem.field_co_cats_name,
          },
        ],
        field_co_logo: {
          relationships: {
            field_media_image: {
              uri:
                {url:dataItem.logo_url === ""
                  ? "/no-image.jpg"
                  :  dataItem.logo_url,}
            },
          },
        },
        field_district: {
          title: dataItem.field_district_title,
        },
        field_offer: {
          drupal_internal__tid: dataItem?.field_offer_tid,
          name: dataItem.field_offer_name,
          weight: dataItem.field_offer_weight,
        },
        field_street: {
          title: dataItem.field_street_title,
          field_street_prefix: dataItem.field_street_prefix,
        },
      },
    }
  })

  return {
    items:
      searchAround !== false && userPosition !== false
        ? orderBy(items, ["distance"], ["asc"])
        : items,
    facet_count: {
      facet_fields: {
        im_field_categories: orderBy(
          Object.values(im_field_categories).filter((item) => item.count > 0),
          ["count"],
          ["desc"]
        ),
        is_field_nom_ville: orderBy(
          Object.values(is_field_nom_ville).filter((item) => item.count > 0),
          ["count"],
          ["desc"]
        ),
      },
    },
  }
}

const addFilterTag = (data) => {
  data.activeFilters = true
  return data
}

export const setLastListPath = (path) => ({
  type: SOLR_SET_LAST_LIST_PATH,
  path: path,
})

export const fetchSolr = (word, tid, villenid, parentTid, withoutVille) => {
  withoutVille = withoutVille || false
  tid = tid || false
  villenid = villenid || false
  parentTid = parentTid || false
  // const params = { ...defaultParams }
  const searchAround = store.getState().global.searchAround
  const userPosition = store.getState().global.userPosition
  // const fq = [...defaultParams.fq]
  // const transformResponse = [defautTransform]

  // if (word) {
  //   params.q = word
  // }
  // if (tid) {
  //   fq.push('im_field_categories$tid:"' + tid + '"')
  // }
  // if (villenid) {
  //   fq.push('is_field_nom_ville$nid:"' + villenid + '"')
  // }
  // if (parentTid) {
  //   fq.push('im_field_categories$parents_all:"' + parentTid + '"')
  // }
  // if ((word && tid) || (word && villenid) || (villenid && !withoutVille)) {
  //   transformResponse.push(addFilterTag)
  // }
  // params.fq = fq
  // if (searchAround !== false && userPosition !== false) {
  //   params.sort =
  //     "geodist(locs_field_position$latlon," +
  //     userPosition[1] +
  //     ", " +
  //     userPosition[0] +
  //     ") asc, " +
  //     defaultParams.sort
  // }

  const params = {}
  const transformResponse = [mainTransformResponse]
  if (word && word !== "") {
    params.search = word
  }
  if (tid) {
    params.cat = tid
    transformResponse.push(addFilterTag)
  }
  if (villenid) {
    params.city = villenid
    transformResponse.push(addFilterTag)
  }
  return {
    type: FETCH_SOLR_LOAD,
    payload: {
      client: "DrupalRest",
      request: {
        url: "/recherche",
        // paramsSerializer: (params) =>
        //   Qs.stringify(params, { arrayFormat: "repeat" }),
        params: params,
        transformResponse: transformResponse,
      },
      city: villenid,
      categorie: tid,
      categorieParent: parentTid,
      searchPos: searchAround ? userPosition : false,
    },
  }

  // return {
  //   type: FETCH_SOLR_LOAD,
  //   payload: {
  //     client: "solR",
  //     request: {
  //       url: "select",
  //       paramsSerializer: (params) =>
  //         Qs.stringify(params, { arrayFormat: "repeat" }),
  //       params: params,
  //       transformResponse: transformResponse,
  //     },
  //     city: villenid,
  //     categorie: tid,
  //     categorieParent: parentTid,
  //     searchPos: userPosition,
  //   },
  // }
}

export const resultSetWord = (word) => ({
  type: SOLR_SET_WORD,
  word: word,
})

export const solrReset = () => ({
  type: SOLR_RESET,
})

export const solrResetItems = () => ({
  type: SOLR_RESET_ITEMS,
})

export const solrResetFilters = () => ({
  type: SOLR_RESET_FILTERS,
})
export const solrSetItems = (items) => ({
  type: SOLR_SET_ITEMS,
  items,
})
