import React, { useRef } from "react"
import { Box } from "rebass/styled-components"
import { getValue, renameImage } from "../../../utils"
import RatioBox from "../RatioBox"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const ImageWrapper = ({ hasRatio, children, isMap }) => {
  return (
    <RatioBox
      ratio={
        hasRatio === "crop_new_slider" || !hasRatio
          ? isMap
            ? [190 / 300, 280 / 920, 190 / 300]
            : [190 / 300, 190 / 300, 280 / 920]
          : [280 / 920]
      }
    >
      {children}
    </RatioBox>
  )
}

const TopSlider = ({ node, isMap }) => {
  const photos = getValue(node, "field_co_slider_free", false, []).map(
    (photo, index) => {
      return renameImage(
        getValue(photo, "relationships", "field_media_image.uri.url", false)
      )
    }
  )

  const imgToken = new Date().toISOString().split("T")[0]
  const slider = useRef()
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  if (photos.length === 0) {
    return null
  }
  return (
    <Box
      pb={2}
      sx={{
        ".slick-dots": {
          bottom: "5px",
          width: "auto",
        },
        ".slick-dots li": {
          bg: "gray50",
          borderRadius: "20px",
          width: "10px",
          height: "10px",
          margin: "0 3px",
          "&.slick-active": {
            bg: "primary",
          },
          "button:before": {
            opacity: "0 !important",
          },
        },
      }}
    >
      {photos.length > 1 ? (
        <Slider ref={slider} {...settings}>
          {photos.map((photo, index) => (
            <ImageWrapper hasRatio={false} key={index} isMap={isMap}>
              <Box
                variant="bgImg"
                sx={{
                  backgroundImage: "url(" + photo + "?v=" + imgToken + ")",
                  backgroundSize: "contain",
                }}
                width="100%"
                height="100%"
              ></Box>
            </ImageWrapper>
          ))}
        </Slider>
      ) : (
        <ImageWrapper hasRatio={false} isMap={isMap}>
          <Box
            variant="bgImg"
            sx={{
              backgroundImage: "url(" + photos[0] + "?v=" + imgToken + ")",
              backgroundSize: "contain",
            }}
            width="100%"
            height="100%"
          ></Box>
        </ImageWrapper>
      )}
    </Box>
  )
}
export default TopSlider
