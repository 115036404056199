import isEmpty from "lodash/isEmpty"
import colors from "../theme/colors"
import { useEffect, useRef } from "react"
import axios from "axios"

import moment from "moment"
import "moment/locale/fr"
// export const apiUrl =
//   process?.env?.NODE_ENV === "production"
//     ? "https://plannc-backend.prod.lfk.nc/"
//     : "http://plannc-backend-d10.ddev.site/"
// //
// export const apiUrl = "https://plannc-backend-d10.ddev.site/"
export const apiUrl = "https://api.plan.nc/"

export const getApiUrl = () => {
  return apiUrl
}

export const paymentListLabels = {
  espece: "Espèces",
  cheque: "Chèques",
  "3x": "En 3 fois",
  "4x": "En 4 fois",
  cb: "Carte Bleue",
  virement: "Virement",
  visa: "Visa",
  mastercard: "Master Card",
  amex: "AMEX",
}

export const convertDate = (date, format, inputFormat) => {
  format = format || false
  inputFormat = inputFormat || false
  var out = moment(date)
  if (inputFormat) {
    out = moment(date, inputFormat)
  }
  if (format) {
    return out.isValid() ? out.format(format) : ""
  }
  return out
}
export const axiosInstance = axios.create({
  baseURL: apiUrl,
  // timeout: 1000,
  headers: { "Content-Type": "application/json" },
  // withCredentials: true,
})
export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const getPath = (url) => {
  url = typeof url === "string" ? url : ""
  return url.replace(
    /^((?:https?:)|(?:public?:))?(?:\/\/?)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/g,
    ""
  )
}

export const getErrorMessage = (response) => {
  const drupalMessage = {
    "Not Acceptable : Account is temporarily blocked.":
      "Le compte est temporairement bloqué, veuiller contacter l'administrateur.",
    "Unauthorized : Wrong username or password.":
      "Mot de passe ou nom d'utilisateur erroné.",
    "Wrong username or password.": "Mot de passe ou nom d'utilisateur erroné.",
  }
  var errorMessage = ""
  if (response.data.form_errors) {
    Object.keys(response.data.form_errors).map((key) => {
      var line = response.data.form_errors[key].replace(/<[^>]*>?/gm, "")
      line = drupalMessage[line] ? drupalMessage[line] : line
      errorMessage += line.replace(/<[^>]*>?/gm, "") + "\n"
      return ""
    })
  } else {
    if (Array.isArray(response.data)) {
      response.data.map((errorline) => {
        var line = drupalMessage[errorline]
          ? drupalMessage[errorline]
          : errorline
        errorMessage += line.replace(/<[^>]*>?/gm, "") + "\n"
        return ""
      })
    } else {
      errorMessage = response.data?.message
      // var line = drupalMessage[response.data]
      //   ? drupalMessage[line]
      //   : response.data
      // errorMessage += line.replace(/<[^>]*>?/gm, "") + "\n"
    }
  }
  return errorMessage
    .replaceAll("Not Acceptable : ", "")
    .replaceAll("Ã©", "é")
    .replaceAll("Ã", "à")
}
export const getOffre = (node, defaultValue) => {
  defaultValue = defaultValue || "gratuit"
  const offre = getValue(node, "field_offer", "name", defaultValue)
  if (typeof offre !== "string" || offre === "") {
    return defaultValue
  }

  return offre.toLowerCase()
}
export const getValue = (node, fieldName, type, defaultValue) => {
  type = type || false
  if (typeof defaultValue === "undefined") {
    defaultValue = false
  }
  if (typeof node !== "object") {
    return defaultValue
  }

  if (!node?.hasOwnProperty(fieldName)) {
    return !node?.relationships?.hasOwnProperty(fieldName)
      ? defaultValue
      : getValue(node.relationships, fieldName, type, defaultValue)
  }

  if (type === false) {
    return node[fieldName] === null ? defaultValue : node[fieldName]
  }
  if (typeof type === "string") {
    if (type === "Raw") {
      return node[fieldName]
    }
    if (type === "Phone") {
      return formatPhone(node[fieldName])
    }
    const types = type.split(".")
    const nextField = types[0]
    types.shift()

    return getValue(node?.[fieldName], nextField, types.join("."), defaultValue)
  }
}
export const getValueOff = (node, fieldName, type, defaultValue) => {
  type = type || false
  if (typeof defaultValue === "undefined") {
    defaultValue = false
  }
  if (typeof node !== "object") {
    return defaultValue
  }
  if (
    !node.hasOwnProperty(fieldName) &&
    !node?.relationships.hasOwnProperty(fieldName)
  ) {
    return defaultValue
  }
  if (node[fieldName] === null) {
    return node?.relationships[fieldName] === null
      ? defaultValue
      : getValue(node?.relationships, fieldName, type, defaultValue)
  }
  if (type === false) {
    return node[fieldName] === null ? defaultValue : node[fieldName]
  }
  if (typeof type === "string") {
    if (type === "Raw") {
      return node[fieldName].und === null ? defaultValue : node[fieldName].und
    }
    if (type === "Phone") {
      return node[fieldName].und === null
        ? defaultValue
        : formatPhone(node[fieldName].und[0])
    }
    const types = type.split(".")
    if (types.length === 1) {
      return node?.[fieldName]?.[types[0]] === null
        ? defaultValue
        : node[fieldName]?.[types[0]]
    }
    if (types.length === 2) {
      return node[fieldName] === null
        ? defaultValue
        : node[fieldName][types[0]] === null ||
            node[fieldName][types[0]].length === 0
          ? defaultValue
          : node[fieldName][types[0]][types[1]]
    }
    if (types.length === 3) {
      return node?.[fieldName] === null
        ? defaultValue
        : node?.[fieldName]?.[types[0]] === null ||
            node?.[fieldName]?.[types[0]].length === 0
          ? defaultValue
          : node?.[fieldName]?.[types[0]]?.[types[1]] === null ||
              node?.[fieldName]?.[types[0]]?.[types[1]].length === 0
            ? defaultValue
            : node?.[fieldName]?.[types[0]]?.[types[1]]?.[types[2]]
    }
    if (types.length === 4) {
      return node?.[fieldName]?.[types[0]]?.[types[1]]?.[types[2]]?.[
        types[3]
      ] === null ||
        node?.[fieldName]?.[types[0]]?.[types[1]]?.[types[2]]?.[types[3]]
          .length === 0
        ? defaultValue
        : node?.[fieldName]?.[types[0]]?.[types[1]]?.[types[2]]?.[types[3]]
    }
  }
}

export const isMembreGratuit = (node) => {
  return (
    parseInt(getValue(node, "field_offer", "drupal_internal__tid", 2078)) ===
    2078
  )
}

export const getLogo = (node, defaultImg) => {
  defaultImg = defaultImg || "/no-image.jpg"
  if (isMembreGratuit(node)) {
    return defaultImg
  }
  return getValue(
    node,
    "field_co_logo",
    "relationships.field_media_image.uri.url",
    defaultImg
  )
}

export const isPremium = (node) => {
  return (
    parseInt(getValue(node, "field_offer", "drupal_internal__tid", 2079)) ===
    2079
  )
}

export const hasRdv = (node) => {
  return getValue(node, "field_rdv_enable", "", false) && isPremium(node)
}

export const getNodePath = (node, isMap) => {
  const path = node?.path?.alias || ""
  return isMap ? "/entreprises/" + path.split("/").slice(-1)[0] : path
}

export const renameImage = (imgLink, defaultImg, style) => {
  if (imgLink === "/no-image.jpg") {
    return imgLink
  }
  imgLink = imgLink || false
  defaultImg = defaultImg || false
  style = style || false
  const path = style ? "/files/styles/" + style + "/public/" : "/files/"
  if (!imgLink) {
    return defaultImg
  }
  return apiUrl + imgLink.replace("public://", path)
}

export const formatPhone = (number) => {
  return number
    ? {
        link: "tel:+687" + number,
        text: number.match(/.{1,2}/g).join(" "),
      }
    : false
}

export const youTubeGetID = (url) => {
  var ID = ""
  url = url || ""
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/)
  if (url[3] !== undefined) {
    ID = url[3]
  } else {
    ID = url
  }
  return ID
}

export const getValidUrl = (url, withoutHttp) => {
  withoutHttp = withoutHttp || false
  if (!url) {
    return false
  }

  if (url === "") return ""
  let newUrl =
    typeof window !== "undefined" ? window.decodeURIComponent(url) : url
  newUrl = newUrl.trim().replace(/\s/g, "")
  newUrl = newUrl.replace(/^https?:\/\//i, "")
  newUrl = newUrl.replace(/https?:\/\//gi, "")
  newUrl = newUrl.replace(/http:/gi, "")
  newUrl = newUrl.replace(/https/gi, "")
  newUrl = newUrl.replace(/http/gi, "")
  newUrl = newUrl.replace(/:/gi, "")
  newUrl = newUrl.replace(/\/\//gi, "")
  return withoutHttp ? newUrl : `https://${newUrl}`
}

export const getColorName = (node) => {
  const offre = getOffre(node)
  var colorname

  switch (offre) {
    case "premium":
      colorname = "purple"
      break
    case "gold":
      colorname = "golden"
      break
    case "gratuit":
      colorname = "primary"
      break
    default:
      colorname = "primary"
  }
  return colorname
}
export const getIconName = (node) => {
  return getOffre(node, "lieux")
}

export const isOpenNow = (hours) => {
  hours = hours || false
  if (!hours || isEmpty(hours)) {
    return false
  }
  const nowNew = new Date()
  const nowDay = nowNew.getDay()
  const nowMin =
    nowNew.getMinutes() > 9 ? nowNew.getMinutes() : "0" + nowNew.getMinutes()
  const nowHourMin = nowNew.getHours() + "" + nowMin
  const open = hours.find(
    (o) =>
      parseInt(o.day) === parseInt(nowDay) &&
      parseInt(o.starthours) <= parseInt(nowHourMin) &&
      parseInt(o.endhours) >= parseInt(nowHourMin)
  )
  return open ? true : false
}

export const capitalize = (s) => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const cleanString = (s) => {
  if (typeof s !== "string") return s
  return s.replace("&#039;", "'").replace("' ", "'")
}

export const getNodeTitle = (node) => {
  const preTitle =
    getValue(node, "field_street_prefix_2") && node.type === "street"
      ? getValue(node, "field_street_prefix_2") + " "
      : ""
  const title = capitalize(cleanString(preTitle + node.title))
  return title
}

export const createMarker = (node, isCurrent) => {
  isCurrent = isCurrent || false
  return {
    center: [
      getValue(node, "field_geofield", "lon", "", 0),
      getValue(node, "field_geofield", "lat", "", 0),
    ],
    node: node,
    hasPos:
      getValue(node, "field_geofield", "lat", "", 0) !== 0 &&
      getValue(node, "field_geofield", "lon", "", 0) !== 0,
    color: colors[getColorName(node)],
    icon: getIconName(node),
    nid: getValue(node, "drupal_internal__nid"),
    drupal_internal__nid: getValue(node, "drupal_internal__nid"),
    isCurrent: isCurrent,
    type: getValue(node, "node_type", "drupal_internal__target_id"),
    title: node.title,
    path: getValue(node, "path", "alias"),
  }
}

export const createCategorieBreadCrumb = (categorie) => {
  const crumbs = []
  if (categorie) {
    // console.log(categorie, "categoriebreadcrumb")
    crumbs.push({
      path: categorie.path.alias,
      title: categorie.name,
    })
    if (categorie.relationships?.parent?.length > 0) {
      crumbs.push({
        path: categorie.relationships?.parent[0]?.path?.alias,
        title: categorie.relationships?.parent[0]?.name,
      })
      if (
        categorie.relationships?.parent[0]?.relationships?.parent[0]?.length > 0
      ) {
        crumbs.push({
          path: categorie.relationships?.parent[0]?.relationships?.parent[0]
            ?.path.alias,
          title:
            categorie.relationships?.parent[0]?.relationships?.parent[0]?.name,
        })
        if (
          categorie.relationships?.parent[0]?.relationships?.parent[0]
            ?.relationships?.parent[0]?.length > 0
        ) {
          crumbs.push({
            path: categorie.relationships?.parent[0]?.relationships?.parent[0]
              ?.relationships?.parent[0]?.path.alias,
            title:
              categorie.relationships?.parent[0]?.relationships?.parent[0]
                ?.relationships?.parent[0]?.name,
          })
        }
      }
    }
  }
  return crumbs.reverse()
}

export const getUserPosition = (cb) => {
  if (typeof window !== "undefined" && typeof cb === "function") {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(cb)
    }
  }
}
/*
 * Formatage des distance
 */
export const formatDistance = (distance) => {
  if (distance === 9999999999) return "Indisponible"
  if (distance < 1000) {
    return Math.round(distance) + " m"
  } else {
    var formatDistance = distance / 1000
    formatDistance = formatDistance.toString()
    var n = formatDistance.indexOf(".")
    n = n + 2
    formatDistance = formatDistance.substring(0, n)
    formatDistance = formatDistance.replace(".", ",")
    return formatDistance + " Km"
  }
}
/*
 * Calcul de la distande depuis la position actuelle
 */
export const getDistance = (pos1, pos2) => {
  if (pos1.lat === 0 || pos2.lat === 0) return 9999999999
  //function from http://www.geodatasource.com/developers/javascript
  const radlat1 = (Math.PI * pos1.lat) / 180
  const radlat2 = (Math.PI * pos2.lat) / 180
  const theta = pos1.lon - pos2.lon
  const radtheta = (Math.PI * theta) / 180
  var dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  dist = dist * 1.609344
  dist = dist * 1000
  return dist
}

/*
 * Calcul de la distande depuis la position actuelle
 */
export const getDistanceFromNode = (userPosition, node) => {
  return getDistance(
    { lat: userPosition[1], lon: userPosition[0] },
    {
      lat: parseFloat(getValue(node, "field_geofield", "lat", "", 0)),
      lon: parseFloat(getValue(node, "field_geofield", "lon", "", 0)),
    }
  )
}

export const sendEvent = (categorie, action, name, value) => {
  if (typeof window !== "undefined") {
    if (typeof window._paq !== "undefined") {
      window._paq.push(["trackEvent", categorie, action, name, value])
    }
  }
}
