import React, { useEffect, useState } from "react"
import { Box, Text, Heading, Flex, Button } from "rebass/styled-components"
import IconPlan from "../UI/icons/IconPlan"
import { Select } from "@rebass/forms/styled-components"
import Loader from "../UI/Loader"
import { connect } from "react-redux"
import Message from "../UI/Message"
import Moment from "moment"
import { extendMoment } from "moment-range"
import "moment/locale/fr"
// import uniq from "lodash/uniq"
// import { getValue } from "../../utils"
import { axiosInstance } from "../../utils"
const moment = extendMoment(Moment)
moment.locale("fr")

const Step1 = ({ node, rdv, setRdv, setStep, step }) => {
  const [intervalApi, setIntervalApi] = useState([])
  const [dayList, setDayList] = useState([])
  const [startTimeStamp, setStartTimeStamp] = useState(moment().format("X"))
  const [fromNow, setFromNow] = useState(true)
  const [loading, setLoading] = useState(true)
  const [prestations, setPrestations] = useState([1])

  useEffect(() => {
    if ((node && startTimeStamp, rdv.prestation)) {
      setLoading(true)
      const duree = rdv.prestations.reduce(
        (a, b) => parseInt(a) + parseInt(b.field_presta_duree),
        0
      )
      axiosInstance
        .get(
          "/v2/rdv_dispos_v2/" +
            node.drupal_internal__nid +
            "/" +
            startTimeStamp +
            "/" +
            duree +
            "/" +
            rdv.prestation.field_presta_granularite
        )
        .then(({ data }) => {
          if (data.success) {
            data.intervals = data.intervals || []
            setLoading(false)
            setIntervalApi(data.intervals.sort())
            setDayList(data.asked_days.sort())
          }
          return
        })
    }
  }, [node, startTimeStamp, rdv.prestations])

  useEffect(() => {
    if (prestations.length === rdv.prestations.length + 1) {
      setRdv({
        ...rdv,
        prestation: node.field_rdv_prestations[0],
        prestations: [...rdv.prestations, node.field_rdv_prestations[0]],
      })
    }
  }, [prestations])

  const addPrestation = () => {
    if (prestations.length < 4) {
      setPrestations([...prestations, 1])
    }
  }
  const removePrestation = (indexToRemove) => {
    if (prestations.length > 1) {
      setPrestations(
        prestations.filter((prestation, index) => index !== indexToRemove)
      )
    }
  }

  if (node) {
    const title = node.title.replace("&#039;", "'")

    const daysFinal = {}
    dayList.map((timestamp) => {
      var dayTimestamp = moment(timestamp, "X").startOf("day").format("X")
      if (!daysFinal.hasOwnProperty(dayTimestamp)) {
        daysFinal[dayTimestamp] = []
      }
      return ""
    })
    intervalApi.map((timestamp) => {
      var dayTimestamp = moment(timestamp, "X").startOf("day").format("X")
      if (!daysFinal.hasOwnProperty(dayTimestamp)) {
        daysFinal[dayTimestamp] = []
      }
      daysFinal[dayTimestamp].push(timestamp)
      return ""
    })

    // const changeTimeStamp = (day) => {
    //   const firstTimestamp = Object.keys(daysFinal)[0]
    //   const lastTimestamp =
    //     Object.keys(daysFinal)[Object.keys(daysFinal).length - 1]
    //   const horaires = getValue(node, "field_rdv_horaires", "Raw", [])
    //   const daysList = uniq(horaires.map((horaire) => horaire.day))

    //   var i = 1
    //   var offset = 1
    //   var beforeTimestamp = moment().format("X")
    //   while (i <= Math.abs(day)) {
    //     if (
    //       daysList.includes(
    //         moment(firstTimestamp, "X")
    //           .startOf("day")
    //           .subtract(offset, "day")
    //           .format("E")
    //       )
    //     ) {
    //       beforeTimestamp = moment(firstTimestamp, "X")
    //         .startOf("day")
    //         .subtract(offset, "day")
    //         .format("X")
    //       i++
    //     }
    //     offset++
    //   }
    //   if (
    //     moment(firstTimestamp, "X").startOf("day").format("X") ===
    //       moment().startOf("day").format("X") &&
    //     day < 0
    //   ) {
    //     return
    //   }
    //   const newTimestamp =
    //     day > 0
    //       ? moment(lastTimestamp, "X").startOf("day").add(1, "day").format("X")
    //       : beforeTimestamp
    //   if (newTimestamp > moment().format("X")) {
    //     setFromNow(false)
    //     setStartTimeStamp(newTimestamp)
    //   } else {
    //     setFromNow(true)
    //     setStartTimeStamp(moment().format("X"))
    //   }
    // }

    const nextPage = () => {
      const lastTimestamp =
        Object.keys(daysFinal)[Object.keys(daysFinal).length - 1]
      const newTimestamp = moment(lastTimestamp, "X")
        .startOf("day")
        .add(1, "day")
        .format("X")
      setFromNow(false)
      setStartTimeStamp(newTimestamp)
    }
    const prevPage = () => {
      const firstTimestamp = Object.keys(daysFinal)[0]
      const newTimestamp = moment(firstTimestamp, "X")
        .startOf("day")
        .subtract(4, "day")
        .format("X")
      setFromNow(false)
      setStartTimeStamp(newTimestamp)
    }

    return (
      <Box width="100%">
        <Box textAlign="center">
          <Text fontWeight="bold" color="primary" pb={2}>
            Prendre un Rendez-vous avec
          </Text>
          <Heading pb={4} as="h1" variant="h3">
            {title}
          </Heading>
        </Box>
        {rdv.error !== false && (
          <Message
            status="error"
            mb={2}
            onClose={() => setRdv({ ...rdv, error: false })}
          >
            {rdv.error}
          </Message>
        )}
        <Box py={4}>
          <Text fontWeight="bold">Type de prestation</Text>
          <Box my={2} height="2px" bg="gray10" />
          {prestations.map((v, index) => (
            <Box
              key={index}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box width={[8 / 10]}>
                <Select
                  my={2}
                  id="presta"
                  name="presta"
                  onChange={(e) => {
                    var prestationsTemp = [...rdv.prestations]
                    prestationsTemp[index] =
                      node.field_rdv_prestations[e.target.value]
                    setRdv({
                      ...rdv,
                      prestation: {
                        ...node.field_rdv_prestations[e.target.value],
                      },
                      prestations: [...prestationsTemp],
                      date: false,
                    })
                  }}
                >
                  {node.field_rdv_prestations.map((prestation, iOption) => {
                    var prix =
                      prestation.field_presta_prix !== "" &&
                      prestation.field_presta_prix !== null
                        ? " - " + prestation.field_presta_prix + " F"
                        : ""
                    const duree = prestation.field_hide_presta_duree
                      ? ""
                      : parseInt(prestation.field_presta_duree) < 60
                        ? moment(
                            parseInt(prestation.field_presta_duree) * 60,
                            "X"
                          )
                            .utc()
                            .format("m") + " min"
                        : moment(
                            parseInt(prestation.field_presta_duree) * 60,
                            "X"
                          )
                            .utc()
                            .format("H") +
                          "h" +
                          (moment(
                            parseInt(prestation.field_presta_duree) * 60,
                            "X"
                          )
                            .utc()
                            .format("m") > 0
                            ? moment(
                                parseInt(prestation.field_presta_duree) * 60,
                                "X"
                              )
                                .utc()
                                .format("m")
                            : "")
                    return (
                      <option key={iOption} value={iOption}>
                        {prestation.field_presta_nom}
                        {prix} {duree ? "(" + duree + ")" : ""}
                      </option>
                    )
                  })}
                </Select>
              </Box>
              {index > 0 && (
                <Box>
                  <Button
                    className="icon-plan-close"
                    sx={{
                      padding: "10px",
                      backgroundColor: "white",
                      borderRadius: "50px",
                      border: "1px solid red",
                      color: "danger",
                      "&:hover": { backgroundColor: "white" },
                    }}
                    my={2}
                    onClick={() => removePrestation(index)}
                  ></Button>
                </Box>
              )}
            </Box>
          ))}
          {node.field_rdv_prestations.length > 1 && prestations.length < 4 && (
            <Button my={2} variant="primaryGradiantBig" onClick={addPrestation}>
              Ajouter une nouvelle prestation
            </Button>
          )}
        </Box>
        <Box py={4}>
          <Text pt={2} fontWeight="bold">
            Date et heure du rendez-vous
          </Text>
          <Box my={2} height="2px" bg="gray10" />
          {intervalApi.length > 0 && fromNow && !loading && (
            <Box textAlign="center">
              <Text
                fontWeight="bold"
                color="primary"
                pb={2}
              >{`Prochain rendez-vous disponible le ${moment(
                Object.keys(daysFinal)[0],
                "X"
              ).format("D MMMM")}`}</Text>
            </Box>
          )}

          {intervalApi.length === 0 && (
            <Box width="100%">
              {loading && <Loader message="Chargement du planning" />}
              {!loading && (
                <Box textAlign="center">
                  <Text fontWeight="bold" color="primary" pb={2}>
                    Pas de rendez-vous disponibles du{" "}
                    {moment(startTimeStamp, "X").format("D MMMM")} au{" "}
                    {moment(startTimeStamp, "X").add(4, "day").format("D MMMM")}
                  </Text>
                  <Button
                    variant="primary"
                    p={2}
                    onClick={nextPage}
                  >
                    <Flex alignItems="center">
                      <Box pr={1}>Voir les jours suivants</Box>
                      <IconPlan icon="arrow_right" size={24} />
                    </Flex>
                  </Button>
                </Box>
              )}
            </Box>
          )}
          {intervalApi.length > 0 && (
            <Flex>
              <Button
                variant="ninja"
                p={2}
                sx={{
                  cursor:
                    moment(startTimeStamp, "X").startOf("day").format("X") ===
                    moment().startOf("day").format("X")
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                <IconPlan
                  icon="arrow_left"
                  size={24}
                  color={
                    moment(startTimeStamp, "X").startOf("day").format("X") ===
                    moment().startOf("day").format("X")
                      ? "muted"
                      : "primary"
                  }
                  onClick={prevPage}
                />
              </Button>
              {loading && <Loader message="Chargement du planning" />}
              {Object.keys(daysFinal).length > 0 && !loading && (
                <>
                  {Object.keys(daysFinal).map((dayTimeStamp, i) => {
                    return (
                      <Box
                        key={i}
                        textAlign="center"
                        bg="grayLight"
                        width={1 / 4}
                      >
                        <Box bg="white" pb={3}>
                          <Text
                            display={["none", "none", "block"]}
                            fontWeight="bold"
                          >
                            {moment(dayTimeStamp, "X").format("dddd")}
                          </Text>
                          <Text display={["none", "none", "block"]}>
                            {moment(dayTimeStamp, "X").format("D MMMM")}
                          </Text>
                          <Text
                            display={["block", "block", "none"]}
                            fontWeight="bold"
                          >
                            {moment(dayTimeStamp, "X").format("ddd")}
                          </Text>
                          <Text display={["block", "block", "none"]}>
                            {moment(dayTimeStamp, "X").format("D MMM")}
                          </Text>
                        </Box>
                        <Box p={[0, 0, 2]}>
                          {daysFinal[dayTimeStamp].length > 0 &&
                            daysFinal[dayTimeStamp].map(
                              (rdvTimestamp, indexSlot) => {
                                return (
                                  <Box key={indexSlot} textAlign="center" p={1}>
                                    <Button
                                      variant={
                                        rdvTimestamp === rdv.date
                                          ? "primary"
                                          : "muted"
                                      }
                                      px={[1, 1, 2]}
                                      width={1 / 1}
                                      onClick={() => {
                                        setRdv({
                                          ...rdv,
                                          date: rdvTimestamp,
                                        })
                                      }}
                                    >
                                      {moment(rdvTimestamp, "X").format(
                                        "HH:mm"
                                      )}
                                    </Button>
                                  </Box>
                                )
                              }
                            )}
                        </Box>
                      </Box>
                    )
                  })}
                </>
              )}
              <Button variant="ninja" p={2}>
                <IconPlan
                  icon="arrow_right"
                  size={24}
                  color="primary"
                  onClick={nextPage}
                />
              </Button>
            </Flex>
          )}
        </Box>
        {rdv.date !== "" && rdv.date !== false && rdv.prestation !== "" && (
          <Flex
            justifyContent="center"
            sx={{
              background: "#fff",
              position: "sticky",
              width: "calc(100% + 20px)",
              padding: "20px",
              paddingBottom: "60px",
              left: "-20px",
              bottom: "-50px",
              margin: "0 -10px",
              boxShadow: "rgba(0, 0, 0, 0.4) 0px -60px 20px -60px",
            }}
          >
            <Button
              variant="primaryGradiantBig"
              width={["auto", "auto", 1 / 3]}
              onClick={() => setStep(step + 1)}
            >
              Continuer
            </Button>
          </Flex>
        )}
      </Box>
    )
  }
  return <></>
}
const mapStateToProps = (state) => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(Step1)
