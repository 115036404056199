import React from "react"
import { Box } from "rebass/styled-components"
import { getValue, sendEvent, getValidUrl } from "../../../utils"
import ContactLink from "./ContactLink"

const Contact = ({ node }) => {
  const standard = getValue(node, "field_phone", "Phone")
  const portable = getValue(node, "field_phone_mob", "Phone")
  const fax = getValue(node, "field_fax", "Phone")
  const liens = getValue(node, "field_website", false, [])

  const mail = getValue(node, "field_email")
  if (standard || portable || fax) {
    return (
      <Box pt="4">
        {standard && (
          <ContactLink
            type="fixe"
            mb={4}
            {...standard}
            onClick={() =>
              sendEvent("phone", "clic-phone", node.drupal_internal__nid, 1)
            }
          />
        )}
        {portable && (
          <ContactLink
            type="mobile"
            mb={4}
            {...portable}
            onClick={() =>
              sendEvent("phone", "clic-phone", node.drupal_internal__nid, 1)
            }
          />
        )}
        {fax && <ContactLink type="fax" mb={4} {...fax} />}
        {mail && (
          <ContactLink type="mail" mb={4} link={"mailto:" + mail} text={mail} />
        )}

        {liens.map((lien) => {
          if (lien?.uri === "" || lien?.uri === null) {
            return null
          }
          const uris = lien.uri.split(",")
          return (
            <>
              {uris.map((uri) => {
                return (
                  <ContactLink
                    key={"lien-" + uri}
                    type="lien"
                    mb={4}
                    link={getValidUrl(uri)}
                    text={getValidUrl(uri, true)}
                  />
                )
              })}
            </>
          )
        })}
      </Box>
    )
  } else {
    return <></>
  }
}
export default Contact
